<template>
  <div class="addDialog">
    <el-dialog title="订单详情" :visible.sync="addDialog" width="1000px">
      <div class="top">
        <titleCpn title="客户信息" />
        <el-input
          v-model="value1"
          placeholder="请选择客户"
          @focus="selectCustomer"
        ></el-input>
        <el-button
          size="small"
          type="primary"
          @click="selectCustomer"
          style="margin-left: 10px;"
          >请选择</el-button
        >
        <el-table
          ref="table"
          :data="selectList1"
          style="width: 100%"
          v-if="selectList1.length > 0"
        >
          <el-table-column
            label="序号"
            type="index"
            width="50"
            align="center"
          ></el-table-column>
          <template v-for="item in tableHeader1">
            <el-table-column
              :label="item.name"
              :prop="item.prop"
              :min-width="item.minWidth"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{ scope.row[item.prop] }}</span>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
      <div class="bottom">
        <titleCpn title="物料信息" />
        <!-- <el-input
          v-model="value2"
          placeholder="请选择物料"
          @focus="selectMaterials"
        ></el-input>
        <el-button
          size="small"
          type="primary"
          @click="selectMaterials"
          style="margin-left: 10px;"
          >请选择</el-button
        > -->
        <el-table
          ref="table"
          :data="selectList2"
          style="width: 100%"
          v-if="selectList2.length > 0"
        >
          <el-table-column
            label="序号"
            type="index"
            width="50"
            align="center"
          ></el-table-column>
          <template v-for="item in tableHeader2">
            <el-table-column
              :label="item.name"
              :prop="item.prop"
              :min-width="item.minWidth"
              align="center"
              v-if="item.prop !== 'productCode'"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="selectList2[scope.$index].num"
                  placeholder="请输入"
                  v-if="item.prop === 'num'"
                  style="width: 100px;"
                  type="number"
                  :min="1"
                  @input="checkMin(scope.$index)"
                ></el-input>
                <el-date-picker
                  v-model="selectList2[scope.$index].planReachDate"
                  type="date"
                  placeholder="选择日期"
                  value="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  v-else-if="item.prop === 'planReachDate'"
                >
                </el-date-picker>
                <el-input
                  v-model="selectList2[scope.$index].remark"
                  placeholder="请输入"
                  v-else-if="item.prop === 'remark'"
                  style="width: 100px;"
                ></el-input>
                <span v-else>{{ scope.row[item.prop] }}</span>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
      <div class="info">
        <titleCpn title="其它信息" />
        <el-form ref="form" :model="form" :rules="rules" label-width="88px">
          <el-row>
            <el-col :span="6">
              <el-form-item label="金融单号：" prop="bankingNumber">
                <el-input
                  v-model="form.bankingNumber"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="联单号：" prop="jointNumber">
                <el-input
                  v-model="form.jointNumber"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="运输方式：" prop="transportType">
                <el-input
                  v-model="form.transportType"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单类型：" prop="orderType">
                <el-input
                  v-model="form.orderType"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="receipt">
        <titleCpn title="收货信息" />
        <el-form ref="form1" :model="form1" :rules="rules1" label-width="88px">
          <el-row>
            <el-col :span="6">
              <el-form-item label="收货人：" prop="consignee">
                <el-input
                  v-model="form1.consignee"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="联系方式：" prop="phone">
                <el-input
                  v-model="form1.phone"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="收货地址：" prop="address">
                <el-input
                  v-model="form1.address"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer">
        <el-button size="small" @click="closeDialog">取消</el-button>
        <el-button size="small" type="primary" @click="handleSave"
          >确定</el-button
        >
      </span>

      <!-- 选择客户 -->
      <el-dialog
        title="请选择客户"
        :visible.sync="customerDialog"
        width="1000px"
        append-to-body
      >
        <div class="saerch1">
          <el-input
            v-model.trim="queryInfo.condition.customerName"
            placeholder="请输入客户名称"
            clearable
          ></el-input>
          <el-button type="primary" icon="el-icon-search" @click="serch1"
            >查询</el-button
          >
        </div>
        <el-input
          v-model="selectValue1"
          placeholder="已选人员"
          readonly
        ></el-input>
        <el-table
          ref="multipleTable"
          :data="customerTableData"
          style="width: 100%"
          v-loading="customerLoading"
          @row-click="handleRowClick1"
          :row-key="getRowKey"
          :max-height="maxTableHeight"
          @select-all="onSelectAll"
          @select="handleSelect"
        >
          <el-table-column
            type="selection"
            width="50"
            :reserve-selection="true"
            class-name="selection-column"
          >
          </el-table-column>
          <el-table-column
            label="序号"
            type="index"
            width="50"
            align="center"
          ></el-table-column>
          <template v-for="item in tableHeader1">
            <el-table-column
              :label="item.name"
              :prop="item.prop"
              :min-width="item.minWidth"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{ scope.row[item.prop] }}</span>
              </template>
            </el-table-column>
          </template>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.currPage"
          :page-sizes="[50, 100, 300, 500]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
        <span slot="footer">
          <el-button size="small" @click="closeDialog1">取消</el-button>
          <el-button size="small" type="primary" @click="handleSave1"
            >确定</el-button
          >
        </span>
      </el-dialog>

      <!-- 选择物料 -->
      <!-- <el-dialog
        title="请选择物料"
        :visible.sync="materialsDialog"
        width="1000px"
        append-to-body
      >
        <el-input
          v-model="selectValue2"
          placeholder="已选物料"
          readonly
        ></el-input>
        <el-table
          ref="multipleTable"
          :data="materialsTableData"
          style="width: 100%"
          v-loading="materialsLoading"
          @selection-change="handleSelectionChange2"
        >
          <el-table-column
            type="selection"
            width="50"
            class-name="selection-column"
          >
          </el-table-column>
          <el-table-column
            label="序号"
            type="index"
            width="50"
            align="center"
          ></el-table-column>
          <template v-for="item in tableHeader2">
            <el-table-column
              :label="item.name"
              :prop="item.prop"
              :min-width="item.minWidth"
              align="center"
              v-if="
                item.prop !== 'num' &&
                  item.prop !== 'planReachDate' &&
                  item.prop !== 'remark'
              "
            >
              <template slot-scope="scope">
                <span>{{ scope.row[item.prop] }}</span>
              </template>
            </el-table-column>
          </template>
        </el-table>
        <span slot="footer">
          <el-button size="small" @click="closeDialog2">取消</el-button>
          <el-button size="small" type="primary" @click="handleSave2"
            >确定</el-button
          >
        </span>
      </el-dialog> -->
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import titleCpn from "../../../../components/titleCpn/index.vue";
import eventBus from "@/utils/event-bus";

export default {
  components: { titleCpn },
  data() {
    return {
      value1: "",
      value2: "",
      customerDialog: false,
      customerLoading: false,
      tableHeader1: [
        {
          name: "订单编号",
          prop: "reconnaissanceCode",
          minWidth: "120px"
        },
                {
          name: "客户名称",
          prop: "customerName",
          minWidth: "100px"
        },
                {
          name: "项目名称",
          prop: "projectName",
          minWidth: "200px"
        },
        // {
        //   name: "勘探人员",
        //   prop: "reconnaissanceName",
        //   minWidth: "80px"
        // },

        {
          name: "手机号",
          prop: "mobile",
          minWidth: "100px"
        },
        {
          name: "收货地址",
          prop: "address",
          minWidth: "120px"
        }
      ],
      customerTableData: [],
      selectValue1: "",
      selectList1: [],
      materialsDialog: false,
      materialsLoading: false,
      materialsTableData: [],
      selectValue2: "",
      selectList2: [],
      tableHeader2: [
        // {
        //   name: "物料编号",
        //   prop: "productCode",
        //   minWidth: "120px"
        // },
        {
          name: "物料名称",
          prop: "productName",
          minWidth: "100px"
        },
        {
          name: "规格型号",
          prop: "specs",
          minWidth: "100px"
        },
        {
          name: "品牌",
          prop: "brand",
          minWidth: "100px"
        },
        {
          name: "数量/台数",
          prop: "num",
          minWidth: "120px"
        },
        {
          name: "功率",
          prop: "power",
          minWidth: "100px"
        },
        {
          name: "单位",
          prop: "unit",
          minWidth: "80px"
        },
        {
          name: "物料单价/元",
          prop: "unitPrice",
          minWidth: "100px"
        },
        {
          name: "计划到货时间",
          prop: "planReachDate",
          minWidth: "200px"
        },
        {
          name: "备注",
          prop: "remark",
          minWidth: "200px"
        }
      ],
      totalNum: 0,
      totalPrice: 0,
      form: {},
      rules: {
        // bankingNumber: [{ required: true, message: "请输入", trigger: "blur" }],
        // jointNumber: [{ required: true, message: "请输入", trigger: "blur" }],
        // transportType: [{ required: true, message: "请输入", trigger: "blur" }],
        // orderType: [{ required: true, message: "请输入", trigger: "blur" }]
      },
      // 收货信息表单
      form1: {},
      rules1: {},
      customerList: [],
      selectTableData: [], // 已选人员
      queryInfo: {
        currPage: 1,
        pageSize: 50,
        condition: {
           orderSchedule :'待发货申请'
        }
      },
      total: 0,
      maxTableHeight: "368px"
    };
  },
  computed: {
    ...mapState("orderList", [""]),
    addDialog: {
      get() {
        return this.$store.state.orderList.addDialog;
      },
      set(val) {
        this.$store.commit("orderList/changeAddDialog", val);
      }
    }
  },
  watch: {
    addDialog: {
      handler(newVal) {
        if (newVal) {
        } else {
          this.form = {};
          this.selectList1 = [];
          this.selectList2 = [];
          this.selectValue1 = "";
          this.customerList = [];
          this.totalNum = 0;
          this.totalPrice = 0;
        }
      }
    },
    customerDialog: {
      handler(newVal) {
        if (newVal) {
        } else {
          this.queryInfo.condition = {
          orderSchedule :'待发货申请'
          };
          this.queryMaterialsList(this.customerList);
        }
      }
    },
    selectList1: {
      handler(newVal) {
        console.log("selectList1", this.selectList1);
        let nameList = newVal?.map(item => item.customerName);
        this.selectValue1 = nameList?.join(",");
        this.customerList = newVal?.map(item => item.customerCode);
        //
        this.setTableSelection();
      },
      deep: true
    }
  },

  methods: {
    ...mapMutations("orderList", ["changeAddDialog"]),
    // 新建下单
    async handleSave() {
      if (!this.selectList1 || this.selectList1.length === 0) {
        this.$message.warning("请选择客户");
        return;
      }

      if (!this.selectList2 || this.selectList2.length === 0) {
        this.$message.warning("请选择物料");
        return;
      }

      for (let i = 0; i < this.selectList2.length; i++) {
        if (!this.selectList2[i].num) {
          this.$message.warning("请选择物料数量!");
          return;
        }

        // if (!this.selectList2[i].planReachDate) {
        //   this.$message.warning("请选择物料计划到货时间!");
        //   return;
        // }
      }

      this.selectList1.forEach(item => {
        delete item.id;
      });

      // 获取总数量
      // 获取总金额
      this.totalNum = 0;
      this.totalPrice = 0;
      this.selectList2.forEach(item => {
        delete item.id;

        if (item.num) {
          this.totalNum += Number(item.num);
        }

        if (item.num && item.unitPrice) {
          item.itemPrice = Number(item.num) * Number(item.unitPrice);

          this.totalPrice += Number(item.num) * Number(item.unitPrice);
        }
      });
      this.totalPrice = parseFloat(this.totalPrice).toFixed(2);

      // this.$refs.form.validate(async valid => {
      //   if (valid) {
      let data = {
        ...this.form,
        ...this.form1,
        customerList: this.selectList1,
        itemList: this.selectList2,
        num: Number(this.totalNum),
        price: parseFloat(this.totalPrice),
        settlePrice: parseFloat(this.totalPrice),
        createBy: JSON.parse(localStorage.getItem("userInfo")).userInfo
          .userCode,
        createName: JSON.parse(localStorage.getItem("userInfo")).userInfo.name
      };
      // 发送请求
      const res = await this.$http.post("/houseOrder/createOrder", data);
      if (res.data.code === 200) {
        this.$message.success("新增成功");
        this.changeAddDialog(false);
        this.form = {};
        this.selectList1 = [];
        this.selectList2 = [];
        this.selectValue1 = "";
        this.selectValue2 = "";
        this.totalNum = 0;
        this.totalPrice = 0;
        eventBus.emit("addSuccess");
      } else {
        this.$message.error(res.data.message);
      }
      //   }
      // });
    },
    closeDialog() {
      this.changeAddDialog(false);
    },
    async selectCustomer() {
      this.customerDialog = true;
      this.customerLoading = true;
      const res = await this.$http.post("/houseCustomer/list", this.queryInfo);
      if (res.data.code == 200) {
        this.customerTableData = res.data.data.data;
        this.total = res.data.data.count;
        this.customerLoading = false;
        //
        this.setTableSelection();
      } else {
        this.$message.error(res.data.message);
      }
    },
    // 组件自己方法
    // handleSelectionChange1(val) {
    //   console.log(val, "客户数据"); // 被选中的行数据数组
    //   this.selectList1 = val;

    //   let arr = val.map(item => item.customerName);
    //   this.selectValue1 = arr.join(",");
    //   // //当前选中列表
    //   // this.selectTableData = val.map(item => item.customerCode);
    //   //
    //   this.customerList = val.map(item => item.customerCode);
    //   // this.queryMaterialsList(this.customerList);
    // },
    closeDialog1() {
      this.customerDialog = false;
    },
    handleSave1() {
      this.customerDialog = false;
    },
    // 之前物料选择弹窗
    // async selectMaterials() {
    //   this.materialsLoading = true;
    //   const res = await this.$http.post("/houseAgentProduct/queryList", {});
    //   console.log("res", res.data);
    //   if (res.data.code == 200) {
    //     this.materialsTableData = res.data.data;
    //     this.materialsLoading = false;
    //     this.materialsDialog = true;
    //   } else {
    //     this.$message.error(res.data.message);
    //   }
    // },
    // handleSelectionChange2(val) {
    //   console.log(val); // 被选中的行数据数组
    //   this.selectList2 = val;

    //   let arr = val.map(item => item.productName);
    //   this.selectValue2 = arr.join(",");
    // },
    // closeDialog2() {
    //   this.materialsDialog = false;
    // },
    // handleSave2() {
    //   this.materialsDialog = false;
    // },
    checkMin(index) {
      if (this.selectList2[index].num < 1) {
        this.selectList2[index].num = 1;
        this.$message({
          message: `值不能小于1`,
          type: "warning"
        });
      }

      // // 获取总数量
      // // 获取总金额
      // this.totalNum = 0;
      // this.totalPrice = 0;
      // this.selectList2.forEach(item => {
      //   if (item.num) {
      //     this.totalNum += Number(item.num);
      //   }

      //   if (item.num && item.price) {
      //     this.totalPrice += Number(item.num) * Number(item.price);
      //   }
      // });

      // this.totalPrice = parseFloat(this.totalPrice).toFixed(2);
    },
    // 客户点击
    handleRowClick1(row, column, event) {
      this.$refs.multipleTable.toggleRowSelection(row);
      //
      let index = this.isExists(row.customerCode);
      if (index === -1) {
        this.selectList1.push(row);
      } else {
        this.selectList1.splice(index, 1);
      }
    },
    async queryMaterialsList(customerCodeList) {
      const res = await this.$http.post(
        "/houseCustomerCombo/queryCustomerProductList",
        {
          customerCodeList
        }
      );

      if (res.data.code == 200) {
        this.selectList2 = res.data.data;
      } else {
        this.selectList2 = [];
        this.$message.error(res.data.message);
      }
    },
    setTableSelection() {
      this.$refs.multipleTable?.clearSelection();
      this.customerTableData?.forEach(row => {
        if (this.customerList?.includes(row.customerCode)) {
          this.$refs.multipleTable?.toggleRowSelection(row, true);
        }
      });
    },
    async handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      await this.selectCustomer();
      this.setTableSelection();
    },
    async handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      await this.selectCustomer();
      this.setTableSelection();
    },
    async serch1() {
      await this.selectCustomer();
      this.setTableSelection();
    },
    getRowKey(row) {
      return row.customerCode;
    },
    onSelectAll(selection) {
      if (selection.length > 0) {
        this.customerTableData.forEach(item => {
          let index = this.isExists(item.customerCode);
          if (index === -1) {
            this.selectList1.push(item);
          }
        });
      } else {
        this.customerTableData.forEach(item => {
          let index = this.isExists(item.customerCode);
          if (index != -1) {
            this.selectList1.splice(index, 1);
          }
        });
      }
    },
    isExists(customerCode) {
      let existsIndex = this.selectList1?.findIndex(
        item => item.customerCode === customerCode
      );
      // console.log(existsIndex);
      return existsIndex;
    },
    handleSelect(selection, row) {
      this.$refs.multipleTable?.clearSelection();

      if (selection.length !== this.customerTableData.length) {
        this.handleRowClick1(row);
      } else {
        this.onSelectAll(selection);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.info {
  // margin-top: 30px;
}
.saerch1 {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  /deep/ .el-input {
    width: 200px;
    margin-right: 10px;
  }
}

/deep/ .el-dialog__body {
  padding-top: 0;
}

.top {
  /deep/ .el-input {
    width: 180px;
  }
}
.bottom {
  /deep/ .el-input {
    width: 180px;
  }
}

/deep/ .selection-column .cell {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/deep/ .el-checkbox:last-of-type {
  margin-right: 0;
}

/deep/ .el-form-item {
  margin-bottom: 0px;
}

/deep/ .el-form-item__label {
  padding: 0;
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* 滚动条轨道的颜色 */
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #4d53e8; /* 滚动条滑块的颜色 */
}

/deep/ .el-table tr:hover > td {
  background-color: #f59a23 !important;
}

/deep/ .el-table__body tr.hover-row > td {
  background-color: #f59a23 !important;
}
</style>

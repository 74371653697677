<template>
  <div class="fontDialog">
    <el-dialog title="订单详情" :visible.sync="showDialog" width="1000px">
      <div class="top">
        <titleCpn title="订单信息" />
      </div>
      <div class="info">
        <div class="one">{{ "非标产品" }}</div>
        <div class="two" v-if="materialsData">
          {{ `订单号:${materialsData.orderNumber}` }}
        </div>
        <div class="three" v-if="materialsData">
          {{ `订单下单日期::${materialsData.orderDate}` }}
        </div>
      </div>
      <div class="steps">
        <el-steps :space="200" :active="activeIndex" finish-status="success">
          <el-step title="发起"></el-step>
          <el-step title="待支付"></el-step>
          <el-step title="已提交"></el-step>
          <el-step title="已接单"></el-step>
          <el-step title="已发货"></el-step>
          <el-step title="已签收"></el-step>
        </el-steps>
      </div>
      <div class="table">
        <el-table ref="table" :data="orderDetailData" style="width: 100%">
          <el-table-column
            label="序号"
            type="index"
            width="50"
            align="center"
          ></el-table-column>
          <template v-for="item in tableHeader">
            <el-table-column
              :label="item.name"
              :prop="item.prop"
              :min-width="item.minWidth"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{ scope.row[item.prop] }}</span>
              </template>
            </el-table-column>
          </template>
          <!-- <el-table-column
            label="操作"
            align="center"
            prop="bihua"
            width="150"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="primary"
                plain
                @click="showmaterialsDetail(scope.row)"
                >查看物料详情</el-button
              >
            </template>
          </el-table-column> -->
        </el-table>
      </div>
      <div class="address">
        <titleCpn title="收货信息" />
        <!-- <el-form label-width="120px">
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="客户名称：" prop="topic">
                <el-input v-model="materialsData.topic" readonly></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form> -->
      </div>
      <span slot="footer">
        <el-button size="small" @click="closeDialog">取消</el-button>
        <el-button size="small" type="primary" @click="handleSave"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import titleCpn from "../../../../components/titleCpn/index.vue";
import eventBus from "@/utils/event-bus";

export default {
  components: { titleCpn },
  data() {
    return {
      tableHeader: [
        {
          name: "物料编号",
          prop: "productCode",
          minWidth: "120px"
        },
        {
          name: "物料名称",
          prop: "productName",
          minWidth: "100px"
        },
        {
          name: "规格型号",
          prop: "specs",
          minWidth: "100px"
        },
        {
          name: "品牌",
          prop: "brand",
          minWidth: "100px"
        },
        {
          name: "数量/台数",
          prop: "num",
          minWidth: "120px"
        },
        {
          name: "单位",
          prop: "unit",
          minWidth: "80px"
        },
        {
          name: "物料单价/元",
          prop: "price",
          minWidth: "100px"
        },
        {
          name: "计划到货时间",
          prop: "planArriveDate",
          minWidth: "200px"
        },
        {
          name: "备注",
          prop: "remark",
          minWidth: "200px"
        }
      ]
    };
  },
  computed: {
    ...mapState("orderList", ["materialsData", "orderDetailData"]),
    showDialog: {
      get() {
        return this.$store.state.orderList.showDialog;
      },
      set(val) {
        this.$store.commit("orderList/changeShowDialog", val);
      }
    },
    activeIndex() {
      switch (this.materialsData?.orderStatus) {
        case 1:
          return 0;
        case 2:
          return 1;
        case 3:
          return 2;
        case 4:
          return 3;
        case 5:
          return 4;
        case 6:
          return 5;
        default:
          return 0;
      }
    }
  },

  methods: {
    ...mapMutations("orderList", [
      "changeShowDialog",
      "changeMaterialsDialog",
      "changeOrderDetailDialog",
      "changeMaterialsList"
    ]),
    handleSave() {},
    closeDialog() {
      this.changeShowDialog(false);
    },
    // 查看物料详情
    async showmaterialsDetail() {
      // const res = await this.$http.post("/houseAgentProduct/queryOne", {
      //   id: row.id
      // });
      // console.log("222", res.data.data);
      // if (res.data.code === 200) {
      //   this.changeMaterialsList(res.data.data);
      // } else {
      //   this.$message.error(res.data.message);
      // }

      this.changeMaterialsDialog(true);
    }
  }
};
</script>

<style lang="less" scoped>
.info {
  display: flex;
  align-items: center;
  padding: 0 100px;
  .one,
  .two,
  .three {
    flex: 1;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }
}
.steps {
  padding-left: 80px;
  margin: 20px 0;
}
/deep/ .el-dialog__body {
  padding-top: 0;
}
</style>

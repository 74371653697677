<template>
  <div ref="search_d" class="search">
    <el-form label-width="120px">
      <el-row style="background:#fff;border-radius:8px;padding-top:7px">
        <el-col :span="6">
          <el-form-item
            label="客户名称："
            prop="customerName"
            label-width="88px"
          >
            <el-input
              v-model="queryInfo.customerName"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="订单编号："
            prop="orderNumber"
            label-width="88px"
          >
            <el-input
              v-model="queryInfo.orderNumber"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="订单类型：" prop="orderType" label-width="88px">
            <el-input
              v-model="queryInfo.orderType"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="订单状态："
            prop="orderStatus"
            label-width="88px"
          >
            <el-select
              v-model.number="queryInfo.orderStatus"
              placeholder="请选择"
              clearable
              style="width: 100%;"
            >
              <el-option
                v-for="item in options"
                :key="item.label"
                :label="item.label"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item label="下单日期：" prop="topic" label-width="88px">
            <el-date-picker
              v-model="registrationTime1"
              type="datetimerange"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="changeTime1"
              clearable
              style="width: 100%;"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item label="订单签收日期：" prop="topic">
            <el-date-picker
              style="width:90%"
              v-model="registrationTime2"
              type="datetimerange"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="changeTime2"
              clearable
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6" style="display: flex; justify-content: flex-end">
          <el-button @click="reset" icon="el-icon-refresh-left">重置</el-button>
          <el-button type="primary" @click="search" icon="el-icon-search"
            >搜索</el-button
          >
          <el-button
            type="primary"
            @click="added"
            icon="el-icon-circle-plus-outline"
            >下单</el-button
          >
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import eventBus from "@/utils/event-bus";

export default {
  data() {
    return {
      queryInfo: {},
      registrationTime1: null,
      registrationTime2: null,
      options: [
        {
          label: "发起",
          value: "1"
        },
        {
          label: "待支付",
          value: "2"
        },
        {
          label: "已提交",
          value: "3"
        },
        {
          label: "已接单",
          value: "4"
        },
        {
          label: "已发货",
          value: "5"
        },
        {
          label: "已签收",
          value: "6"
        }
      ]
    };
  },
  mounted() {
    // this.$nextTick(() => {
    //   const height = this.$refs.search_d.clientHeight;
    //   this.$emit("heightReady", height);
    // });
  },
  methods: {
    ...mapMutations("orderList", [
      "changeLabelType",
      "changeForm",
      "changeShowDialog",
      "changeAddDialog"
    ]),
    reset() {
      this.queryInfo = {};
      eventBus.emit("reset");
    },
    added() {
      this.changeForm({});
      this.changeAddDialog(true);
    },
    search() {
      eventBus.emit("conditionQuery", this.queryInfo);
    },
    changeTime1() {
      // if (val) {
      //   this.queryInfo.applyStartDate = val[0];
      //   this.queryInfo.applyEndDate = val[1];
      // } else {
      //   this.queryInfo.applyStartDate = null;
      //   this.queryInfo.applyEndDate = null;
      // }
    },
    changeTime2() {
      // if (val) {
      //   this.queryInfo.applyStartDate = val[0];
      //   this.queryInfo.applyEndDate = val[1];
      // } else {
      //   this.queryInfo.applyStartDate = null;
      //   this.queryInfo.applyEndDate = null;
      // }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-date-editor .el-range__icon {
  display: flex;
  align-items: center;
}

/deep/ .el-range-separator {
  display: flex;
  align-items: center;
}
/deep/ .el-form-item {
  margin-bottom: 7px;
}

/deep/ .el-form-item__label {
  padding: 0;
}
</style>

<template>
  <div class="storeManagement">
    <topCpn />
    <search-cpn ref="search_d" @heightReady="handleHeightReady" />
    <table-cpn
      :tableHeader="tableHeader"
      :tableData="tableData"
      height="calc(100vh - 322px)"
      :loading="loading"
    />
    <!-- 分页 -->
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.currPage"
        :page-sizes="[50, 100, 300, 500]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <div class="dialog">
      <dialog-cpn />
      <addDialog />
      <materialsDialog />
    </div>
  </div>
</template>

<script>
import searchCpn from "./ch-cpns/searchCpn/index.vue";
import tableCpn from "./ch-cpns/tableCpn/index.vue";
import dialogCpn from "./ch-cpns/dialogCpn/index.vue";
import topCpn from "./ch-cpns/topCpn/index.vue";
import addDialog from "./ch-cpns/addDialog/index.vue";
import materialsDialog from "./ch-cpns/materialsDialog/index.vue"; // 签收信息
import orderDetailDialog from "./ch-cpns/orderDetailDialog/index.vue"; // 并网资料
import eventBus from "@/utils/event-bus";

export default {
  components: {
    searchCpn,
    tableCpn,
    dialogCpn,
    topCpn,
    addDialog,
    materialsDialog
  },
  data() {
    return {
      queryInfo: {
        currPage: 1,
        pageSize: 50,
        condition: {}
      },
      total: 0,
      tableData: [],
      tableHeader: [
                {
          name: "客户名称",
          prop: "customerName",
          minWidth: "120px"
        },
        {
          name: "下单日期",
          prop: "orderDate",
          minWidth: "100px"
        },
        {
          name: "订单号",
          prop: "orderNumber",
          minWidth: "180px"
        },
                {
          name: "订单总额",
          prop: "price",
          minWidth: "120px"
        },
       

        {
          name: "订单状态",
          prop: "orderStatus",
          minWidth: "120px"
        },
        
                {
          name: "订单签收日期",
          prop: "signDate",
          minWidth: "120px"
        },
        {
          name: "金融单号",
          prop: "bankingNumber",
          minWidth: "120px"
        },
         {
          name: "联单号",
          prop: "jointNumber",
          minWidth: "100px"
        },
        {
          name: "运输方式",
          prop: "transportType",
          minWidth: "100px"
        },
        {
          name: "订单类型",
          prop: "orderType",
          minWidth: "120px"
        },
      ],
      headerHeight: 0,
      height: window.innerHeight - 250, //表格高度
      loading: false
    };
  },
  watch: {},
  created() {
    this.getList();
    // 监听添加成功事件
    eventBus.on("addSuccess", this.addSuccess);
    // 监听条件查询事件
    eventBus.on("conditionQuery", this.conditionQuery);
    // 监听重置事件
    eventBus.on("reset", this.reset);
    // 监听取消订单成功事件
    eventBus.on("cancelOrder", this.cancelOrder);
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.getHeight());
    });
  },
  destroyed() {
    eventBus.off("addSuccess", this.addSuccess);
    eventBus.off("conditionQuery", this.conditionQuery);
    eventBus.off("reset", this.reset);
    eventBus.off("cancelOrder", this.cancelOrder);
  },
  methods: {
    async getList() {
      this.loading = true;
      let data = JSON.parse(JSON.stringify(this.queryInfo));
      if (data.condition.orderStatus === "发起") {
        data.condition.orderStatus = 1;
      } else if (data.condition.orderStatus === "待支付") {
        data.condition.orderStatus = 2;
      } else if (data.condition.orderStatus === "已提交") {
        data.condition.orderStatus = 3;
      } else if (data.condition.orderStatus === "已接单") {
        data.condition.orderStatus = 4;
      } else if (data.condition.orderStatus === "已发货") {
        data.condition.orderStatus = 5;
      } else if (data.condition.orderStatus === "已签收") {
        data.condition.orderStatus = 6;
      }

      data.condition.userCodeList = JSON.parse(
        localStorage.getItem("userInfo")
      ).userInfo.userCodeList;
      const res = await this.$http.post("/houseOrder/list", data);
      if (res.data.code == 200) {
        this.total = res.data.data.count;
        this.tableData = res.data.data.data;
        this.loading = false;
      } else {
        this.$message.error(res.data.message);
      }
    },
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList();
    },
    getHeight() {
      this.height = window.innerHeight - this.headerHeight - 230;
    },
    // 添加成功
    addSuccess() {
      this.getList();
    },
    // 条件查询
    conditionQuery(data) {
      this.queryInfo.condition = data;
      this.getList();
    },
    // 重置
    reset() {
      this.queryInfo.condition = {};
      this.getList();
    },
    cancelOrder() {
      this.getList();
    },
    handleHeightReady(height) {
      this.headerHeight = height;
    }
  }
};
</script>

<style lang="less" scoped>
.storeManagement {
  // height: calc(100vh - 75px);
  padding: 7px 10px;
  padding-top: 3px;
  background: #f5f7f9;
}
</style>

<template>
  <div class="customerDialog">customerDialog</div>
</template>

<script>
export default {
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  beforeUpdate() {},
  methods: {}
};
</script>

<style lang="less" scoped></style>

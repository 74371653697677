<template>
  <div class="statistics">
    <template v-for="(item, index) in statisticsList">
      <div class="item">
        <div class="top">
          <i class="el-icon-document"></i>
          <div class="tit">{{ item.name }}</div>
        </div>
        <div class="count">{{ item.count }}</div>
        <div class="rate">
          较昨日
          <div class="num">{{ item.rate }}</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      statisticsList: [
        {
          name: "新增订单",
          count: 0,
          rate: 0
        },
        {
          name: "待分配",
          count: 0,
          rate: 0
        },
        {
          name: "订单总数",
          count: 0,
          rate: 0
        },
        {
          name: "新客户",
          count: 0,
          rate: 0
        },
        {
          name: "成交客户",
          count: 0,
          rate: 0
        },
        {
          name: "客户总数",
          count: 0,
          rate: 0
        }
      ]
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  beforeUpdate() {},
  methods: {}
};
</script>

<style lang="less" scoped>
.statistics {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  padding-top: 7px;
  .el-form-item {
    margin-bottom: 12px;
  }
  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 173px;
    height: 76px;
    padding: 0px 17px;
    background: #fff;
    border-radius: 10px;
    .top {
      display: flex;
      justify-content: space-around;
      align-items: center;
      i {
        font-size: 20px;
        color: #409eff;
      }
      .tit {
        font-size: 15px;
        font-weight: 700;
        margin-left: 6px;
      }
    }
    .count {
      font-size: 22px;
      font-weight: 700;
      // margin: 5px 0;
    }
    .rate {
      display: flex;
      align-items: center;
      .num {
        color: #f08848;
      }
    }
  }
}
</style>

<template>
  <div class="materialsDialog">
    <el-dialog title="收货确认" v-dialogDrag :visible.sync="materialsDialog" width="1000px">
      <div class="top">
        <titleCpn title="订单详情" />
        <el-form label-width="120px">
          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item label="订单号：" prop="orderNumber">
                <span>{{ form.orderNumber }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="订单类型：" prop="orderType">
                <span>{{ form.orderType }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="订单状态：" prop="orderStatus">
                <span>{{ handleStatus(form.orderStatus) }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="收货日期：" prop="signDate">
                <span>{{ form.signDate }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="运输类型：" prop="transportType">
                <span>{{ form.transportType }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="table">
        <el-table ref="table" :data="tableData" style="width: 100%">
          <el-table-column
            label="序号"
            type="index"
            width="50"
            align="center"
          ></el-table-column>
          <template v-for="item in tableHeader">
            <el-table-column
              :label="item.name"
              :prop="item.prop"
              :min-width="item.minWidth"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="tableData[scope.$index].signNum"
                  placeholder="请输入"
                  v-if="item.prop === 'signNum'"
                  style="width: 100px;"
                  type="number"
                  :min="1"
                  @input="checkMin(scope.$index)"
                  :readonly="flag"
                ></el-input>
                <span v-else>{{ scope.row[item.prop] }}</span>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
      <div class="sign">
        <titleCpn title="签收信息" />
        <el-form ref="form1" :model="form1" :rules="rules" label-width="120px">
          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item label="签收人：" prop="signee">
                <el-input v-model="form1.signee" :readonly="flag"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系方式：" prop="signeePhone">
                <el-input
                  v-model="form1.signeePhone"
                  :readonly="flag"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="签收时间：" prop="signDate">
                <el-date-picker
                  v-model="form1.signDate"
                  type="datetime"
                  placeholder="选择日期时间"
                  val="yyyy-MM-dd HH:mm:ss"
                  val-format="yyyy-MM-dd HH:mm:ss"
                  style="width: 100%"
                  :readonly="flag"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注：" prop="signRemark">
                <el-input
                  v-model="form1.signRemark"
                  type="textarea"
                  :autosize="{ minRows: 5 }"
                  :readonly="flag"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="签收图片：" prop="signUrl">
                <el-upload
                  :action="$uploadURL"
                  multiple
                  :limit="100"
                  :file-list="fileList_1"
                  :class="{ disabled: flag || fileList_1.length >= 100 }"
                  list-type="picture-card"
                  :on-success="
                    (rep, file, fileList) =>
                      handleSuccess(rep, file, fileList, 1)
                  "
                  :on-error="
                    (err, file, fileList) => handleError(err, file, fileList, 1)
                  "
                  :on-remove="
                    (file, fileList) => handleRemove(file, fileList, 1)
                  "
                  :on-exceed="handleExceed"
                  :on-preview="handlePictureCardPreview"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer">
        <el-button size="small" @click="closeDialog">取消</el-button>
        <el-button size="small" type="primary" @click="handleSave" v-if="!flag"
          >签收</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import eventBus from "@/utils/event-bus";
import { mapState, mapMutations } from "vuex";
import titleCpn from "../../../../components/titleCpn/index.vue";
import drag from '../../../../components/drag';

export default {
  components: { titleCpn },
  data() {
    return {
      tableHeader: [
        {
          name: "物料编号",
          prop: "productCode",
          minWidth: "120px"
        },
        {
          name: "物料名称",
          prop: "productName",
          minWidth: "100px"
        },
        {
          name: "规格型号",
          prop: "specs",
          minWidth: "100px"
        },
        {
          name: "品牌",
          prop: "brand",
          minWidth: "100px"
        },
        {
          name: "数量/台数",
          prop: "num",
          minWidth: "120px"
        },
        // {
        //   name: "单位",
        //   prop: "unit",
        //   minWidth: "80px"
        // },
        // {
        //   name: "物料单价/元",
        //   prop: "price",
        //   minWidth: "100px"
        // },
        // {
        //   name: "计划到货时间",
        //   prop: "planArriveDate",
        //   minWidth: "200px"
        // },
        {
          name: "实际收货数量",
          prop: "signNum",
          minWidth: "200px"
        }
      ],
      // fileList_1: [],
      // form1: {},
      rules: {
        signee: [{ required: true, message: "请输入", trigger: "blur" }],
        signeePhone: [{ required: true, message: "请输入", trigger: "blur" }],
        signDate: [{ required: true, message: "请选择", trigger: "blur" }]
        // signRemark: [{ required: true, message: "请输入", trigger: "blur" }]
      },
      totalNum: 0,
      uploadList: []
    };
  },
  computed: {
    ...mapState("orderList", [
      "form",
      "orderDetailData",
      "form1",
      "fileList_1"
    ]),
    materialsDialog: {
      get() {
        return this.$store.state.orderList.materialsDialog;
      },
      set(val) {
        this.$store.commit("orderList/changeMaterialsDialog", val);
      }
    },
    tableData() {
      let arr = JSON.parse(JSON.stringify(this.orderDetailData));
      arr?.forEach(item => {
        if (!item.signNum) {
          item.signNum = 1;
        }
      });
      return arr;
    },
    flag() {
      if (this.form.orderStatus === 6) {
        return true;
      }
    }
  },
  methods: {
    ...mapMutations("orderList", [
      "changeMaterialsDialog",
      "changeForm1",
      "changeFileList"
    ]),
    closeDialog() {
      this.changeMaterialsDialog(false);
    },
    handleSave() {
      for (let i = 0; i < this.tableData.length; i++) {
        if (!this.tableData[i].signNum) {
          this.$message.warning("请输入实际收货数量!");
          return;
        }
      }

      let arr = this.tableData.map(item => ({
        id: item.id,
        signNum: item.signNum
      }));

      this.$refs.form1.validate(async valid => {
        if (valid) {
          let data = {
            ...this.form1,
            id: this.form.id,
            signNum: Number(this.totalNum),
            itemList: arr,
            orderStatus: 6, // 已签收
            signUrl: this.uploadList.join(",")
          };
          // 发送请求
          const res = await this.$http.post("/houseOrder/signOrder", data);
          if (res.data.code === 200) {
            this.$message.success("签收成功");
            this.changeMaterialsDialog(false);
            // this.fileList_1 = [];
            this.changeForm1({});
            this.totalNum = 0;
            eventBus.emit("addSuccess");
          } else {
            this.$message.error(res.data.message);
          }
        }
      });
    },
    checkMin(index) {
      if (this.tableData[index].signNum < 1) {
        this.tableData[index].signNum = 1;
        this.$message({
          message: `值不能小于1`,
          type: "warning"
        });
      }

      // 获取总数量
      this.totalNum = 0;
      this.tableData?.forEach(item => {
        if (item.signNum) {
          this.totalNum += Number(item.signNum);
        }
      });
    },
    // 成功
    handleSuccess(rep, file, fileList, index) {
      this.$message.success("上传成功");
      this.changeFileList(fileList);
      this.uploadList.push(rep.data.url);
    },
    // 失败
    handleError(err, file, fileList, index) {
      this.$message.error("上传失败");
      this.changeFileList(fileList);
    },
    // 移除
    handleRemove(file, fileList, index) {
      this.$message.success("删除成功");
      this.changeFileList(fileList);
    },
    // 超出限制
    handleExceed(file, fileList) {
      this.$message.warning("超出图片上传数量限制！");
    },
    // 预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleStatus(status) {
      switch (status) {
        case 1:
          return "发起";
        case 2:
          return "待支付";
        case 3:
          return "已提交";
        case 4:
          return "已接单";
        case 5:
          return "已发货";
          break;
        case 6:
          return "已签收";
          break;
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
  padding-top: 0;
}

/deep/ .el-upload--picture-card,
/deep/ .el-upload-list__item {
  width: 90px;
  height: 90px;
  position: relative;
}
/deep/ .el-icon-plus {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
/deep/ .disabled .el-upload--picture-card {
  display: none;
}

// /deep/ .el-form-item__label {
//   text-align: justify;
// }
/deep/ .el-form-item{
  margin-bottom: 18px;
}
/deep/.el-tabel{
  margin-top: 0;
}
/deep/.el-dialog__body{
  padding-bottom: 0;
}
</style>

<template>
  <div class="title">
    <div class="tip"></div>
    <div class="tit">{{ title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="less" scoped>
.title {
  display: flex;
  align-items: center;
  height: 36px;
  .tip {
    width: 4px;
    height: 18px;
    background: #1890ff;
    border-radius: 2px;
    margin-right: 10px;
  }
  .tit {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
  }
}
</style>

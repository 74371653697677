<template>
  <div class="renderTable">
    <el-table
      ref="table"
      @row-dblclick="showDetail"
      :data="tableData"
      :height="height"
      style="width: 100%"
      v-loading="loading"
      header-cell-name="tableHeight"
    >
      <el-table-column
        label="操作"
        align="center"
        prop="bihua"
        width="230"
        fixed="left"
      >
        <template slot-scope="scope">
          <el-button type="primary" plain @click="showDetail(scope.row)"
            >查看</el-button
          >
          <el-button
            type="primary"
            plain
            @click="signInformation(scope.row)"
            style="margin-right: 10px;"
            >签收信息</el-button
          >
          <el-popconfirm
            title="确定取消订单吗？"
            @onConfirm="cancelOrder(scope.row)"
            @confirm="cancelOrder(scope.row)"
          >
            <el-button slot="reference" type="danger" plain>取消订单</el-button>
          </el-popconfirm>

          <!-- <el-popconfirm
            title="确定删除吗？"
            @onConfirm="handleDelte(scope.row.id)"
            @confirm="handleDelte(scope.row.id)"
          >
            <el-button slot="reference" type="text" style="color: red"
              >删除</el-button
            >
          </el-popconfirm> -->
        </template>
      </el-table-column>
      <el-table-column
        label="序号"
        type="index"
        width="50"
        align="center"
      ></el-table-column>
      <template v-for="item in tableHeader">
        <el-table-column
          show-overflow-tooltip
          :label="item.name"
          :prop="item.prop"
          :min-width="item.minWidth"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="item.prop == 'orderStatus'">
              <el-tag v-if="scope.row.orderStatus === 1">发起</el-tag>
              <el-tag type="warning" v-if="scope.row.orderStatus === 2"
                >待支付</el-tag
              >
              <el-tag v-if="scope.row.orderStatus === 3">已提交</el-tag>
              <el-tag type="info" v-if="scope.row.orderStatus === 4"
                >已接单</el-tag
              >
              <el-tag v-if="scope.row.orderStatus === 5">已发货</el-tag>
              <el-tag type="success" v-if="scope.row.orderStatus === 6"
                >已签收</el-tag
              >
            </div>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import eventBus from "@/utils/event-bus";
export default {
  props: {
    tableHeader: {
      type: Array,
      default: []
    },
    tableData: {
      type: Array,
      default: []
    },
    height: {
      type: Number | String,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    ...mapMutations("orderList", [
      "changeForm",
      "changeShowDialog",
      "changeMaterialsData",
      "changeOrderDetailData",
      "changeMaterialsDialog", // 签收
      "changeOrderDetailDialog", // 并网资料
      "changeForm1",
      "changeFileList"
    ]),
    async showDetail(row) {
      await this.queryOne(row);
      await this.queryList(row);

      this.changeForm(row);
      this.changeShowDialog(true);
    },
    // async handleDelte(id) {
    //   const res = await this.$http.post("/event/delete", { id });
    //   if (res.data.code === 200) {
    //     this.$message.success("删除成功");
    //     eventBus.emit("delSuccess");
    //   } else {
    //     this.$message.error(res.data.message);
    //   }
    // }
    // handleStatus(status) {
    //   switch (status) {
    //     case 1:
    //       return "发起";
    //     case 2:
    //       return "待支付";
    //     case 3:
    //       return "已提交";
    //     case 4:
    //       return "已接单";
    //     case 5:
    //       return "已发货";
    //     case 6:
    //       return "已签收";
    //     default:
    //       return "未定义";
    //   }
    // },
    // 签收
    async signInformation(row) {
      await this.queryList(row);
      this.changeForm(row);
      if (row.orderStatus === 6) {
        this.changeForm1(row);
        // 上传
        let arr = [];
        if (row.signUrl && row.signUrl.length > 0) {
          arr = row.signUrl.split(",");
        }
        arr = arr.map(item => ({
          url: item
        }));
        this.changeFileList(arr);
      } else {
        this.changeForm1({});
        this.changeFileList([]);
      }
      this.changeMaterialsDialog(true);
    },
    // 并网资料
    showInformation(row) {
      this.changeForm(row);
      this.changeOrderDetailDialog(true);
    },
    async queryOne(row) {
      const res1 = await this.$http.post("/houseOrder/queryOne", {
        id: row.id
      });
      if (res1.data.code === 200) {
        // res1.data.data
        this.changeMaterialsData(res1.data.data);
      } else {
        this.$message.error(res1.data.message);
      }
    },
    async queryList(row) {
      // 订单信息
      const res2 = await this.$http.post("/houseOrderItem/queryList", {
        orderNumber: row.orderNumber
      });
      if (res2.data.code === 200) {
        // res2.data.data
        this.changeOrderDetailData(res2.data.data);
      } else {
        this.$message.error(res2.data.message);
      }
    },
    // 取消订单
    async cancelOrder(row) {
      const res = await this.$http.post("/houseOrder/cancelOrder", {
        id: row.id
      });
      if (res.data.code === 200) {
        eventBus.emit("cancelOrder");
      } else {
        this.$message.error(res.data.message);
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/.el-pagination {
  margin-bottom: 0;
}
.renderTable {
  padding: 7px;
  // padding-top: 0;
  padding-bottom: 0;
  background-color: #fff;
  border-radius: 6px;
  margin-top: 2px;
  .el-table{
    margin-top: 5px;
  }
}

/deep/ .el-button {
  padding: 0 8px;
}
/deep/ .tableHeight {
  height: 40px;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #02a7f0;
}

/deep/ .el-table td {
  padding: 2px 0;
}

/deep/ .el-table tr:hover > td {
  background-color: #f59a23 !important;
}

/deep/ .el-table__body tr.hover-row > td {
  background-color: #f59a23 !important;
}
/deep/ .el-pagination{
  margin-top: 5px;
}
</style>
